<template>
  <div>
    <div>
      <homeCard />
    </div>
    <div class="mt30 bg-w pd20"></div>
  </div>
</template>

<script>
import homeCard from "./components/homeCard.vue";
export default {
  components: {
    homeCard,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
