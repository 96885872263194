<template>
  <div class="home-card">
    <div class="card-item" :style="`background:${bgColor}`">
      <div class="flex-box">
        <div class="icon">
          <img :src="iconUrl" alt="" />
        </div>
        <div class="col-item" v-for="item in dataKey" :key="item[1]">
          <div>{{ item[0] }}</div>
          <div>{{ dataSource[item[1]] || 0 }}</div>
        </div>
      </div>
      <div class="right-chart">
        <div class="txt">{{ title }}</div>
        <img src="../../../assets/image/chart.png" alt="" />
      </div>
    </div>
    <!-- <div class="process-item">
      <div class="flex-box">
        <div class="col-item">
          <div>访客数量</div>
          <div>60586</div>
        </div>
      </div>
      <div class="right-chart">
        <a-progress :percent="30" />
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    dataKey: {
      type: Array,
      default: () => [],
    },
    bgColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconUrl: require("../../../assets/image/user.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.home-card {
  // border-bottom: 1px solid #efefef;
  .process-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 15px;
    align-items: center;
    .right-chart {
      width: 60%;
    }
  }
  .card-item {
    background: linear-gradient(to right, #8577ec, #957ce0, #a682d4);
    box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flex-box {
      display: flex;
      align-items: center;
      & > div {
        padding: 10px;
      }
      .icon {
        width: 40px;
        border: 1px solid #fff;
        border-radius: 8px;
        img {
          width: 20px;
        }
      }
      .col-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .right-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80px;
      height: 40px;
    }
  }
}
</style>
