<template>
  <div style="overflow-x: hidden; padding-top: 10px">
    <a-row :gutter="[40, 40]">
      <a-col
        :xs="{ span: 24 }"
        :lg="{ span: 12 }"
        v-for="(key, i) in dataKeys"
        :key="key"
      >
        <CardItem
          :dataSource="dataSource[key]"
          :bgColor="colorList[i % colorList.length]"
          :keyStrs="keyStr[key]"
          v-bind="keyStr[key]"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CardItem from "./CardItem.vue";

let keyStr = {
  aturnover: {
    title: "全网访客",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthTurnover"],
      ["本周", "weekTurnover"],
      ["今日", "dayTurnover"],
    ],
  },
  account: {
    title: "代理",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthAdd"],
      ["本周", "weekAdd"],
      ["今日", "dayAdd"],
    ],
  },
  shop: {
    title: "商铺",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthAdd"],
      ["本周", "weekAdd"],
      ["今日", "dayAdd"],
    ],
  },
  applet: {
    title: "小程序访客",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthAdd"],
      ["本周", "weekAdd"],
      ["今日", "dayAdd"],
    ],
  },
  employee: {
    title: "员工",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthAdd"],
      ["本周", "weekAdd"],
      ["今日", "dayAdd"],
    ],
  },
  user: {
    title: "会员",
    dataKey: [
      ["总数", "total"],
      ["本月", "monthAdd"],
      ["本周", "weekAdd"],
      ["今日", "dayAdd"],
    ],
  },
};
let colorList = [
  "linear-gradient(to right, #00f1fd, #27cefe, #4dadff)",
  "linear-gradient(to right, #c2e8fb, #afd5fa, #a1c4fc)",
  "linear-gradient(to right, #fe9a8a, #fe8e8c, #fd858b)",
  "linear-gradient(to right, #00ebba, #00b0ce, #007bdd)",
  "linear-gradient(to right, #8577ec, #957ce0, #a682d4)",
  "linear-gradient(to right, #f9c7d3, #c4abe2, #9996ee)",
  "linear-gradient(to right, #a1bcec, #83a4d8, #6a91c8)",
];

export default {
  components: {
    CardItem,
  },
  data() {
    return {
      keyStr,
      dataSource: {},
      dataKeys: [],
      colorList,
    };
  },
  mounted() {
    this.$http.get("platform/admin/getHomePageData").then((res) => {
      this.dataSource = res;
      // console.log(res);
      this.dataKeys = Object.keys(res);
    });
  },
};
</script>

<style></style>
